import { Pipe, PipeTransform } from '@angular/core';
import {
  ArchBowlEntry,
  ArchBowlEntryTimeBlock,
} from '../@services/competition.service';

@Pipe({
  name: 'testTime',
  standalone: true,
})
export class TestTimePipe implements PipeTransform {
  transform(entry: ArchBowlEntry | null | undefined): number {
    return secondsRemaining(entry);
  }
}

// calculate the time remaining (in seconds) on in timeblocks
function secondsRemaining(entry: ArchBowlEntry | null | undefined): number {
  if (!(entry && entry.test?.timeLimit && entry.test.timeLimit > 0)) {
    return Infinity;
  }
  const { timeBlocks } = entry.state ?? { timeBlocks: [] };
  return calculateTotalSecondsForTimeblocks(timeBlocks);
}

function calculateTotalSecondsForTimeblocks(
  timeBlocks: ArchBowlEntryTimeBlock[],
) {
  let totalSeconds = 0;

  for (const timeBlock of timeBlocks) {
    if (timeBlock.startTime) {
      // Calculate the remaining seconds for the timeBlock that has started
      const endTime =
        new Date(timeBlock.startTime).getTime() + timeBlock.minutes * 60000;
      const now = Date.now();
      const seconds = Math.floor((endTime - now) / 1000);
      totalSeconds += Math.max(seconds, 0);
    } else {
      // Calculate the total seconds for the timeBlock that hasn't started yet
      const startTime = new Date(timeBlock.startTime).getTime();
      const endTime = startTime + timeBlock.minutes * 60000;
      const seconds = Math.floor((endTime - startTime) / 1000);
      totalSeconds += Math.max(seconds, 0);
    }
  }

  return totalSeconds;
}
